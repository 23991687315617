import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		name: 'Index',
		component: () => import('@/views/OutIndex/index.vue'),
		redirect: '/Home',
		children: [
			{	//首页
				path: '/Home',
				name: 'Home',
				component: () => import('@/views/Home/index.vue')
			},
			{	//开箱(正常)
				path: '/Open',
				name: 'Open',
				component: () => import('@/views/Open/index.vue')
			},
			{	//Roll房
				path: '/Roll',
				name: 'Roll',
				component: () => import('@/views/Roll/index.vue')
			},
			{	//Roll房详情
				path: '/room',
				name: 'room',
				component: () => import('@/views/Roll/room.vue')
			},
			{	//免费箱子
				path: '/Activity',
				name: 'Activity',
				component: () => import('@/views/Activity/index.vue')
			},
			{	//免费箱子开箱
				path: '/Activity_open',
				name: 'Activity_open',
				component: () => import('@/views/Activity/Activity_open')
			},
			{	//饰品商城
				path: '/Mall',
				name: 'Mall',
				component: () => import('@/views/Mall/index.vue')
			},
			{	//饰品商城
				path: '/MallRecord',
				name: 'MallRecord',
				component: () => import('@/views/Mall/record.vue')
			},
			// {	//幸运开箱(列表)
			// 	path: '/Lucky',
			// 	name: 'Lucky',
			// 	component: () => import('@/views/Lucky/index.vue')
			// },
			// {	//幸运开箱(开箱)
			// 	path: '/Lucky_open',
			// 	name: 'Lucky_open',
			// 	component: () => import('@/views/Lucky/Lucky_open.vue')
			// },
			{	//充值
				path: '/Recharge',
				name: 'Recharge',
				component: () => import('@/views/Recharge/index.vue')
			},
			{	//充值
				path: '/Integral',
				name: 'Integral',
				component: () => import('@/views/Recharge/integral.vue')
			},
			{	//个人中心
				path: '/Me',
				name: 'Me',
				component: () => import('@/views/Me/index.vue')
			},
			{	//背包
				path: '/Warehouse',
				name: 'Warehouse',
				component: () => import('@/views/Warehouse/index.vue')
			},
			{	//会员推广
				path: '/Extension',
				name: 'Extension',
				component: () => import('@/views/Extension/index.vue')
			},
			{	//修改密码
				path: '/Changepass',
				name: 'Changepass',
				component: () => import('@/views/Changepass/index.vue')
			},
			{	//对战
				path: '/Battle',
				name: 'Battle',
				component: () => import('@/views/Battle/index.vue')
			},
			{//对战详情
				path: '/Combat',
				name: 'Combat',
				component: () => import('@/views/Battle/Combat.vue')
			},
			{//宝箱详情
				path: '/BoxDetail',
				name: 'BoxDetail',
				component: () => import('@/views/Battle/BoxDetail/index.vue')
			},
			{	//对战历史
				path: '/Battlehistory',
				name: 'Battlehistory',
				component: () => import('@/views/Battle/Battlehistory.vue')
			},
			{	//升级合约
				path: '/Replacement',
				name: 'Replacement',
				component: () => import('@/views/Replacement/index.vue')
			},
			{	//升级合约记录
				path: '/ReplacementRecord',
				name: 'ReplacementRecord',
				component: () => import('@/views/Replacement/record.vue')
			},
			//抢卡
			{
			    path: '/RushPurchase',
			    name: 'RushPurchase',
			    component: () => import('@/views/RushPurchase/index.vue')
			},
			{
				path: '/PrizeDetails',
				name: 'PrizeDetails',
				component: () => import('@/views/RushPurchase/PrizeDetails.vue')
			},
			//VIP 
			{
				path: '/VIP',
				name: 'VIP',
				component: () => import('@/views/VIP/index.vue')
			},
			{
				path: '/vipActive',
				name: 'vipActive',
				component: () => import('@/views/Warehouse/vipActive.vue')
			},
			{
				path: '/Welfare',
				name: 'Welfare',
				component: () => import('@/views/Welfare/index.vue'),
			},
			{
				path: '/Double',
				name: 'Double',
				component: () => import('@/views/Activity/double.vue')
			},
			{
				path: '/Spring',
				name: 'Spring',
				component: () => import('@/views/Activity/spring.vue')
			},
			{
				path: '/Message',
				name: 'Message',
				component: () => import('@/views/Message/index.vue')
			},
			{
				path: '/Bill',
				name: 'Bill',
				component: () => import('@/views/Warehouse/bill.vue')
			},
			{
				path: '/Help',
				name: 'Help',
				component: () => import('@/views/Warehouse/help.vue')
			},
			{
				path: '/HelpCom',
				name: 'HelpCom',
				component: () => import('@/views/Warehouse/helpCom.vue')
			},
			{
				path: '/Article',
				name: 'Article',
				component: () => import('@/views/Warehouse/article.vue')
			},
			{
				path: '/Fairness',
				name: 'Fairness',
				component: () => import('@/views/Fairness/index.vue')
			},
		]
	},
	{//支付
		path: '/Payment',
		name: 'Payment',
		component: () => import('@/views/Payment/index.vue'),
	}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
