<template>
	<div id="app">
		<div class="update-con" v-if="maintenance">
			<div class="update-main">
				<div class="scroll-con">
					<el-scrollbar style="height:100%;">
						<div class="content">{{maintenanceContent}}</div>
					</el-scrollbar>
				</div>
			</div>
		</div>
		<template v-else>
			<div class="popup-con1" v-show="isShowIndex">
				<div class="popup-main">
					<div class="content">
						<div class="scroll-con">
							<el-scrollbar style="height:100%;">
								<div class="word-content">{{content}}</div>
							</el-scrollbar>
						</div>
					</div>
					<div class="know-btn" @click="isShowIndex=false"></div>
				</div>
			</div>
			<div class="popup-con1" v-show="isNew">
				<div class="new-red-con">
					<div class="close" @click="isNew=false"></div>
					<div class="new-btn" @click="newReceive"></div>
				</div>
			</div>
			<div class="popup-con1" v-show="isNewGet">
				<div class="new-red-con get">
					<div class="new-price">
						<div class="coins"></div>
						{{ newAward }}
					</div>
					<div class="new-btn" @click="isNewGet=false"></div>
				</div>
			</div>
			<router-view />
		</template>
	</div>
</template>

<script>
	import {
		Join_channel,
		getConfig,
		newUserReward
	} from './network/api.js'
	import md5 from 'blueimp-md5'
	import {
		mapMutations,
		mapState,
		mapActions
	} from 'vuex'
	export default {
		name: 'app',
		data() {
			return {
				path: "wss://api.wanskins.com/ws", //网址
				socket: null,
				client_id: '', //客户端id
				isShowIndex:false,
				content: '',
				newAward: 0,
				isNew: false,
				isNewGet: false
			}
		},
		computed:{
			...mapState([
				'maintenance',
				'maintenanceContent',
				'User',
				'publicConfig'
			]),
		},
		watch: {
			User:{
				handler(val){
					if(val.has_new_user_award === false && this.publicConfig.new_user_award_on == 1){
						this.isNew = true
					}
				},
				deep: true,
				immediate: true
			},
			publicConfig:{
				handler(val){
					if(this.User.has_new_user_award === false && val.new_user_award_on == 1){
						this.isNew = true
					}
				},
				deep: true,
				immediate: true
			}
		},
		created() {
			if (!this._isMobile()) {
				 // window.location.href = process.env.VUE_APP_TARGET
				 document.documentElement.classList.add("PC")
			}
			this.path = process.env.VUE_APP_SOCKET_URL
			this.init()
			this.getConfig()
		},
		methods: {
			...mapActions([
				'Refresh'
			]),
			...mapMutations([
				'CreateGroup',
				'joinGroup',
				'endGroup',
				'setPublicConfig'
			]),
			newReceive(){
				newUserReward().then((res)=>{
					const {code, data, message} = res.data
					this.isNew = false
					this.Refresh()
					this.newAward = data.bean
					this.isNewGet = true
				})
			},
			getConfig(){
				getConfig().then((res) => {
					const {code, data, message} = res.data
					if(code == 200){
						this.setPublicConfig(data || {});
						if(!sessionStorage.getItem('indexShowPopup')){
							this.content = data.site_bulletin
							this.isShowIndex = true;
						  sessionStorage.setItem('indexShowPopup',1)
						}
						
						// this.config = data
					}
				})
			},
			//判断访问设备
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag
			},

			//箱子对战(websocket)
			init() {
				if (typeof(WebSocket) === "undefined") {
					alert("您的浏览器不支持socket")
				} else {
					// 实例化socket
					this.socket = new WebSocket(this.path)
					// 监听socket连接
					this.socket.onopen = this.open
					// 监听socket错误信息
					this.socket.onerror = this.error
					// 监听socket消息
					this.socket.onmessage = this.getMessage
				}
			},
			open() {
				console.log("socket连接成功")
				this.Timing()
			},
			error() {
				console.log("连接错误")
			},
			//处理返回数据
			getMessage(msg) {
				let Data = JSON.parse(msg.data)
				// console.log(Data)
				switch (Data.MsgType) {
					case "connect": //返回客户端id
						this.authentication(Data.Data) //去认证
						break;
					case "auth": //认证成功
						this.ToGroup() //加入对战群组
						break;
					case "CreateGroup": //创建房间
						this.CreateGroup(Data.Data)
						break;
					case "joinGroup": //加入房间
						this.joinGroup(Data.Data)
						break;
					case "endGroup": //对战结束
						this.endGroup(Data.Data)
						break;
					default:
						// startGroup => 对战开始
				}
			},
			send(params) {
				this.socket.send(params)
			},
			close() {
				console.log("socket已经关闭")
			},


			//认证
			authentication(v) {
				this.client_id = v.client_id
				let ran = Math.floor(Math.random() * Math.pow(10, 20)).toString()
				let data = {
					MsgType: 'auth',
					token: '',
					string: ran,
					time: new Date().getTime(),
					app_id: 'csgoskins',
				}
				const temp = v.client_id + ran + data.time + data.app_id + 'h2T*V%&cQx6traMr'
				data.token = md5(temp)
				this.send(JSON.stringify(data))
			},

			//进入对战群组频道
			ToGroup() {
				// console.log(v)
				Join_channel(this.client_id).then((res) => {
					// console.log(res)
				})
			},


			//定时心跳
			Timing() {
				setInterval(() => {
					// console.log('心跳')
					this.send('G')
				}, 18000)
			},

			
		},
		destroyed() {
			// 销毁监听
			this.socket.onclose = this.close
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	html,body{
		overflow-x: hidden;
		// &::-webkit-scrollbar {
		// 	width: 0 !important;
		// 	height: 0 !important;
		// }
		
	}
	body{
		width: 375px;
		overflow-x: hidden;
		background-color: #15161B;
		color: white;
		font-size: 14px;
		min-height: 100vh;
		margin: 0 auto !important;
	}
	div{
		font-size: 14px;
	}
	input{
		background:none;
		outline:none;
		border:none;
	}
	.update-con{
		background:url(~@/assets/img/weihu-bg.png) #000;
		background-attachment:fixed;
		background-position: top center;
		background-size: cover;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		.update-main{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			// color: #000;
			flex-direction: column;
			padding-top: 110px;
			width: 333px;
			height: 313px;
			background: url(~@/assets/img/weihu-img.png);
			background-size: 100% 100%;
		}
		.scroll-con{
			height: 155px;
			width: 280px;
			.el-scrollbar__wrap{
				overflow-x: hidden;
			}
		}
		.content{
			text-align: center;
			font-size: 14px;
			line-height: 18px;
			// color: #3ED1FF;
			white-space: pre-wrap;
		}
	}
	* {
		margin: 0;
		padding: 0;
	}

	body {
		font-family: '微软雅黑';
	}

	.van-button--info {
		background: #ffd322 !important;
		color: #000 !important;
		border: none !important;
	}


	.van-popup {
		z-index: 10003 !important;
	}

	.el-pagination {
		text-align: center;
		padding: 0 !important;

		.btn-prev {
			width: 60px !important;
			background: #2e3850 !important;
			color: #fff !important;
		}

		.btn-next {
			width: 60px !important;
			background: #2e3850 !important;
			color: #fff !important;
		}

		.el-pager {
			li {
				background: #2e3850 !important;
				color: #fff !important;
				margin: 0 2.3px !important;

				&.active {
					background: #1989fa !important;
				}
			}
		}
	}

	.van-overlay {
		z-index: 10001 !important;

		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 375px;
			margin: 0 auto;
			.LogOut_box {
				width: 90%;
				padding: 20px;
				box-sizing: border-box;
				background: #20222b;
				border-radius: 10px;
				position: relative;

				.close {
					position: absolute;
					top: 10px;
					right: 10px;
					width: 20px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.tit {
					width: 100%;
					text-align: center;
					font-size: 22px;
					color: #fff;
					margin-bottom: 5px;
				}

				.text {
					text-align: center;
					color: #fff;
					font-size: 14px;
					margin: 10px 0;
				}

				.van-cell {
					background: none;
					margin-top: 10px;

					.van-field__label {
						width: 50px;
						color: #fff;
					}

					.van-field__control {
						color: #fff;
					}
				}

				.van-cell::after {
					background: #fff;
				}
			}
			.rule-popup-con{
				width: 343px;
				.rule-popup-header{
					height: 40px;
					background: url(~@/assets/img/rule-header.png);
					background-size: 100% 100%;
					@include HScenter;
					position: relative;
					.close{
						position: absolute;
						right: 8px;
						top: 50%;
						transform: translateY(-50%);
						width: 24px;
						height: 24px;
						background: url(~@/assets/img/rule-close.png);
						background-size: 100% 100%;
					}
				}
				.rule-popup-main{
					height: 276px;
					padding: 20px;
					background: #15161B;
					.scroll-con{
						height: 100%;
						.el-scrollbar__wrap{
							overflow-x: hidden;
						}
					}
					.rule-popup-content{
						color: #fff;
						white-space: pre-wrap;
						word-break: break-all;
						font-size: 14px;
						line-height: 26px;
					}
				}
				
			}
		}
	}
	.el-message-box{
		width: 343px;
		border: none;
		border-radius: 0;
		background: #15161B;
	}
	.el-message-box--center .el-message-box__header{
		padding: 0;
		height: 40px;
		background-image: url(~@/assets/img/rule-header.png);
		background-size: 100% 100%;
		@include HScenter;
		.el-message-box__title{
			color: #fff;
		}
	}
	.el-message-box__headerbtn{
		// display: none;
		width: 24px;
		height: 24px;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(~@/assets/img/rule-close.png);
		background-size: 100% 100%;
		.el-message-box__close{
			display: none;
		}
	}
	.el-message-box--center .el-message-box__content{
		padding: 24px 0;
		color: #fff;
	}
	.el-message-box--center .el-message-box__status{
		display: none;
	}
	.el-message-box__btns{
		padding: 0;
		.el-button--small{
			width: 152px;
			height: 40px;
			background: #232330;
			border: none;
			color: #fff;
			border-radius: 0;
		}
	}
	.el-message-box__btns button:nth-child(2){
		background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
	}
	.popup-con1{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;
		.popup-main{
			width: 359px;
			height: 574px;
			color: #fff;
			background-image: url(~@/assets/img/notice-bg.png);
			background-size: 100% 100%;
			padding-top: 122px;
			.content{
				width: 264px;
				height: 334px;
				position: relative;
				margin: 0 auto;
				.scroll-con{
					height: 100%;
					.el-scrollbar__wrap{
						overflow-x: hidden;
					}
				}
				.word-content{
					font-size: 12px;
					line-height: 20px;
					position: relative;
					z-index: 1;
					white-space: pre-wrap;
				}
			}
			.know-btn{
				width: 280px;
				height: 47px;
				margin: 60px auto 0;
				cursor: pointer;
				background: url(~@/assets/img/notice-btn.png);
				background-size: 100% 100%;
			}
		}
	}

	.new-red-con{
		width: 265px;
		height: 383px;
		background-size: 100% 100%;
		background-image: url(~@/assets/img/new-bg.png);
		padding-top: 288px;
		position: relative;
		&.get{
			background-image: url(~@/assets/img/new-bg-get.png);
			padding-top: 126px;
			.new-btn{
				width: 172px;
			}
		}
		.close{
			position: absolute;
			background: url(~@/assets/img/rule-close.png);
			background-size: 100% 100%;
			width: 24px;
			height: 24px;
			top: -35px;
			right: -24px;
			cursor: pointer;
		}
		.new-btn{
			width: 146px;
			height: 43px;
			cursor: pointer;
			margin: 0 auto;
		}
		.new-price{
			@include HScenter;
			font-size: 30px;
			line-height: 36px;
			color: #FF003D;
			margin-bottom: 123px;
			.coins{
				background-image: url(~@/assets/img/coins-red.png);
				width: 30px;
				height: 30px;
				margin-right: 4px;
			}
		}
	}
</style>

