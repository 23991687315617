export default {

  demo(store,val){
	  store.demo = val
  },

  PostUser(state, u) {
    state.User = {...u}
    state.User.email = u.email
    state.User.pass = u.pass
    state.User.name = u.name
    state.User.avatar = u.avatar
    state.User.level = u.level
    state.User.bean = u.bean
    state.User.steam = u.steam_url
	  state.User.steam_id = u.steam_id
    state.User.invite = u.invite_code
  	state.User.promotion_level = u.promotion_level
  	state.User.total_recharge = u.total_recharge
  	state.User.Rebate = u.Rebate
  	state.User.recharge_level = u.recharge_level
  	state.User.charge_rebate = u.charge_rebate
	  state.User.integral = u.integral
    state.User.vip_level  = u.vip_level

  },

  PostIndividualsShow(state,val){
	  state.IndividualsShow = val
  },

  //对战相关

  //创建房间
  CreateGroup(state,val){
	   state.NewGroup = val
  },

  //加入房间
  joinGroup(state,val){
	  state.join = val
  },

  //对战结束(奖品已确定)
  endGroup(state,val){
	  state.end = val
  },
	//是否维护
	setMaintenance(state,val){
	  state.maintenance = val
	},
	//维护内容
	setMaintenanceContent(state,val){
	  state.maintenanceContent = val
	},
	//公共配置
	setPublicConfig(state,val){
	  state.publicConfig = val
	},
}
